module.exports = {
    base_url: '',
    dev_url: '',
    // cysdk_url:'http://120.132.118.58:8100',
    cysdk_url:'https://aigo-stockapi.starmarvel.net',
    release_url: 'https://aigo-centerapi.starmarvel.net',
    upload_url: 'https://aigo-centerapi.starmarvel.net/common/uploadFile',
    // test_url:'http://localhost:2074',
    test_url: 'https://aigo-consoleapi.starmarvel.net',
    // test_url:'http://consoleapi.genkigo.net',
    download_url: 'https://chatgptapi.genkigo.net',

    upload: {
        pic_ext: 'png,jpeg,jpg,gif',
        pic_size: '10485760',
        video_ext: 'mp4,rmvb,avi,mkv',
        video_size: '20971520',
        threeD: 'obj,mtl,png,jpeg,jpg',
    },
    exclude_path: [ //额外路由 不校验用户登陆

    ],
}