<template>
  <div class="app"  style="height: 100%">
    <router-view/>
  </div>
</template>
<style lang="scss">
html{
  height: 100%;
  background-color: #F5F8FC;
}
body{
  margin: 0;
}
#app {
  padding: 0;
  margin: 0;
  height: 100%;
}
</style>
