import {request} from './request';
import cache from './cache';

const http = {
    get(url, params) {
        let token=  cache.get("token");
        const config = {
            method: 'get',
            url: url,
            headers:{
                token:token
            }
        }
        if (params) config.params = params
        return request(config)
    },
    post(url, params,content_type='application/x-www-form-urlencoded') {
        let token=  cache.get("token");
        const config = {
            method: 'post',
            url: url,
            headers:{
                "token":token,
                "Content-Type":content_type,
            }
        };
        if (params) config.data = params
        return request(config)
    },
    put(url, params) {
        const config = {
            method: 'put',
            url: url
        }
        if (params) config.params = params
        return request(config)
    },
    run(url,method, params,content_type='application/x-www-form-urlencoded'){
        let token=  cache.get("token");
        const config = {
            method: method,
            url: url,
            headers:{
                "token":token,
                "Content-Type":content_type,
            }
        };
        if (params) config.data = params
        return request(config)
    },
    delete(url, params) {
        const config = {
            method: 'post',
            url: url
        }
        if (params) config.params = params
        return request(config)
    },
    uploadFile(){

    }
}
export default http