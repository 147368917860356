import axios from "axios";
import GlobalConfig from "./config";

let http_url = window.location.host;
let base_URL = GlobalConfig.release_url;

if (http_url.indexOf("localhost") || http_url.indexOf("192.168")) {
    base_URL = GlobalConfig.test_url; // 这是调用数据接口,公共接口url+调用接口名
} else {
    base_URL = GlobalConfig.release_url; // 这是调用数据接口,公共接口url+调用接口名
}

export function request(config) {
    // 创建axios的实例
    const instance = axios.create({
        baseURL: base_URL,
        timeout: 60000
    })
    //console.log(instance);
    // 请求拦截器配置
    instance.interceptors.request.use(config => {
            // config.headers.cookie = window.sessionStorage.getItem('token')
            return config
        }, error => {
            return Promise.error(error)
        }
    )
    // 响应拦截器配置
    instance.interceptors.response.use(response => {
        return response.data
    }, error => {
        console.log(error);
        // switch (error.response.data.code) {
        //   case 400:
        //     console.log("无权访问")
        //     router.push({path: '/login'})
        //     break
        //   case 404:
        //     console.log("404啦")
        //     router.push({path: '/404'})
        //     break
        //   default:
        //     return Promise.reject(error)
        // }
        return Promise.reject(error)
    })
    // 发送真正的网络请求
    return instance(config);
}

export default request;