import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import( '@/views/index.vue'),
    meta: {
      keep_alive: false,
      title: '主页',
    },
    children:[
      {
        path: '/home',
        name: 'home',
        component: () => import( '@/views/common/home.vue'),
        meta: {
          keep_alive: false,
          title: '主页',
        },
      },{
        path: '/sys/menu',
        name: 'menu',
        component: () => import( '@/views/system/menu.vue'),
        meta: {
          keep_alive: true,
          title: '菜单管理',
        },
      },{
        path: '/sys/admins',
        name: 'sys/admins',
        component: () => import( '@/views/system/admin/admins.vue'),
        meta: {
          keep_alive: true,
          title: '管理员管理',
        },
      },{
        path: '/sys/role',
        name: 'sys/roles',
        component: () => import( '@/views/system/role/roles.vue'),
        meta: {
          keep_alive: true,
          title: '角色管理',
        },
      },{
        path: '/user',
        name: 'user',
        component: () => import( '@/views/user/user.vue'),
        meta: {
          keep_alive: true,
          title: '用户管理',
        },
      },{
        path: '/user/contact',
        name: 'user/contact',
        component: () => import( '@/views/user/strategyContact.vue'),
        meta: {
          keep_alive: true,
          title: '用户认购管理',
        },
      },{
        path: '/fund/positions',
        name: 'fund/positions',
        component: () => import( '@/views/fund/positions.vue'),
        meta: {
          keep_alive: true,
          title: '基金持仓',
        },
      },{
        path: '/fund/picking/log',
        name: 'fund',
        component: () => import( '@/views/fund/pickingLog.vue'),
        meta: {
          keep_alive: true,
          title: '选股记录',
        },
      },{
        path: '/fund/record',
        name: 'fund/record',
        component: () => import( '@/views/fund/record.vue'),
        meta: {
          keep_alive: true,
          title: '持仓记录',
        },
      },{
        path: '/fund/stock/picking',
        name: 'fund/stock/picking',
        component: () => import( '@/views/fund/stockPicking.vue'),
        meta: {
          keep_alive: true,
          title: '智能选股',
        },
      },{
        path: '/fund/trading/log',
        name: 'fund/trading/log',
        component: () => import( '@/views/fund/tradingLog.vue'),
        meta: {
          keep_alive: true,
          title: '交易记录',
        },
      },{
        path: '/fund/trading/strategy',
        name: 'fund/trading/strategy',
        component: () => import( '@/views/fund/tradingStrategy.vue'),
        meta: {
          keep_alive: true,
          title: '交易策略',
        },
      },{
        path: '/fund/data/manage',
        name: 'fund/data/manage',
        component: () => import( '@/views/fund/dataManage.vue'),
        meta: {
          keep_alive: true,
          title: '数据管理',
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/common/login.vue'),
    meta: {
      keep_alive: false,
      title: '登陆',
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
