import http from "./http"
// import {api_url} from './config'
import {checkCache, randomString} from "./utils"
import cache from "./cache"
// import store from '@/store/index'
import router from "@/router";
import config from "./config";
import {ElMessage} from "element-plus";

export function UserInit() {
    let token = cache.get('token');
    return new Promise(function (resolve, reject) {
        // 异步处理  // 处理结束后、调用resolve 或 reject
        if (checkCache(token)) { //如果token未过期
            refreshToken().then((info) => {
                resolve(info);
            }).catch((err) => {
                reject(err);
            })
        } else {
            reject(false);
        }
    });
}

export function getUserInfo() {
    return new Promise(function (resolve, reject) {
        http.get('/user_info/info', {}).then((res) => {
            if (res.code == 0) {
                let token = cache.get('token');
                cache.set(token, res.data);
                // store.commit('setUserInfo',res.data)
                resolve(res.data);
            } else {
                reject(false);
            }
        }).catch((err) => {
            reject(err);
        })
    });
}

export function updateUserInfo(data) {

}

export function checkLogin() {
    //console.log(router.beforeEach);
    router.beforeEach((to, from, next) => {
        if (to.path != '/login') { //检查是否登陆
            UserInit().then(info => {
                /* 路由发生变化修改页面title */
                if (to.meta.title) {
                    // @ts-ignore
                    document.title = to.meta.title
                }
                if (to.path == '/') {
                    next('/home');
                    return;
                }
                next();
            }).catch(err => {
                next({path:'/login'});
                ElMessage({
                    message: '尚未登陆',
                    type: 'error',
                    onClose() {
                        // next({path: '/login?reg=' + reg})

                        // next({path: '/login'})
                    }
                });
                console.log(err)
                return;
            });
        }else{
            next();
        }

    })
}

export function refreshToken() {
    return new Promise(function (resolve, reject) {
        http.post('/sys/admins/checkToken', {}).then((res) => {
            if (res.code == 0) {
                let token = cache.get('token');
                cache.set(token, res.data);
                // store.commit('setUserInfo',res.data);
                // store.commit('setLoginStatus',true);
                resolve(res.data);
            } else {
                reject(false);
            }
        }).catch((err) => {
            reject(err);
        })
    });
}


