import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/reset.css';
import '@/assets/css/base.css';
// import 'lib-flexible/flexible.js'
// import '@/utils/rem'
import * as userUtil from "@/utils/user.js";
import {checkLogin} from "@/utils/user.js";

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// const debounce = (fn, delay) => {
//  let timer = null;
//  return function () {
//   let context = this;
//   let args = arguments;
//   clearTimeout(timer);
//   timer = setTimeout(function () {
//    fn.apply(context, args);
//   }, delay);
//  }
// }
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
//  constructor(callback) {
//   callback = debounce(callback, 16);
//   super(callback);
//  }
// }


// userUtil.checkLogin();
checkLogin();
 createApp(App)
    .use(ElementPlus)
    .use(store)
    .use(router)
    .mount('#app')

